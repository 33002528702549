export const DimensionSettings = {
    "length": {
        "EUR": 120,
        "FIN": 120,
        "HPL": 80,
        "QPL": 60,
        "CTN": 0,
        "PLL": 0,
        "LOAD": ""
    },
    "width": {
        "EUR": 80,
        "FIN": 100,
        "HPL": 60,
        "QPL": 40,
        "CTN": 0,
        "PLL": 0,
        "LOAD": ""
    },
    "height": {
        "EUR": "",
        "FIN": "",
        "HPL": "",
        "QPL": "",
        "CTN": 0,
        "PLL": 0,
        "LOAD": ""
    }
}

export const PackageOptions = {
    "EUR": "EUR pallet (EUR)",
    "FIN": "FIN pallet (FIN)",
    "HPL": "Half pallet (HPL)",
    "QPL": "Quarter pallet (QPL)",
    "CTN": "Package (CTN)",
    "PLL": "Pallet (PLL)",
    "LOAD": "Full load"
}
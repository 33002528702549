import React, { useState } from "react";
import FormPalletRow from "./FormPalletRow";
import { DimensionSettings } from "../helpers/GoodsHelper";

function MultipleRowsModal({setShipmentTotalValues}) {
  const palletDefaultData = {
    type: "EUR",
    quantity: 1,
    length: 120,
    width: 80,
    height: "",
    weight: "",
    cbm: 0,
    ldm: 0.4,
  };

  const [palletRows, setPalletRows] = useState([palletDefaultData]);

  const [shipmentTotal, setShipmentTotal] = useState({
    quantity: 1,
    weight: 0,
    cbm: 0,
    ldm: 0.4,
    package_type: "EUR"
  });

  const removeRow = (id) => {
    const newPalletRows = palletRows.filter(
      (pallet) => palletRows.indexOf(pallet) !== id
    );
    calculateTotals(newPalletRows);
    setPalletRows(newPalletRows);
  };

  const addRow = () => {
    setPalletRows((prevState) => {
      return [...prevState, palletDefaultData];
    });
    calculateTotals(palletRows.concat(palletDefaultData));
  };

  const handleInputChange = (event, id) => {
    const { name, value } = event.target;
    let rowsArray = [...palletRows];
    let obj = rowsArray[id];
    obj[name] = value;
    if (name === "type") {
      obj.length = DimensionSettings.length[value] ?? 0;
      obj.width = DimensionSettings.width[value] ?? 0;
      obj.height = "";
      obj.weight = "";
    }
    obj.ldm = calculateLDM(obj);
    obj.cbm = calculateCBM(obj);

    rowsArray[id] = obj;
    calculateTotals(rowsArray);
    setPalletRows([...rowsArray]);
  };

  const calculateLDM = (pallet) => {
    return roundToTwo(
      ((pallet.length * pallet.width) / 24000) * pallet.quantity
    );
  };

  const calculateCBM = (pallet) => {
    return (
      roundToThree(pallet.length * pallet.width * pallet.height / 1000000 * pallet.quantity)
    );
  };

  const calculateTotals = (pallets) => {
    let totalQuantity = pallets.reduce((a, b) => a + parseInt(b.quantity || 0), 0);
    let totalLDM = pallets.reduce((a, b) => a + roundToTwo(b.ldm || 0), 0);
    let totalCBM = pallets.reduce((a, b) => a + roundToThree(b.cbm || 0), 0);
    let totalWeight = pallets.reduce((a, b) => a + roundToTwo(b.weight || 0), 0);
    let unique_package_types = [...new Set(pallets.map(item => item.type))];
    let package_type = (unique_package_types.length === 1) ? unique_package_types[0] : "PLL";
    setShipmentTotal({
      ...shipmentTotal,
      quantity: totalQuantity || 0,
      ldm: roundToTwo(totalLDM) || 0,
      cbm: roundToThree(totalCBM) || 0,
      weight: totalWeight || 0,
      package_type: package_type
    });
  };

  return (
    <div className="modal" tabIndex="-1" id="multiplePalletsModal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content" style={{ borderRadius: "0px" }}>
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Multiple pallets calculator
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body row ms-1">
            <div className="col-2 px-1 mb-2" style={{ width: "10%" }}>
              <label className="input-label">Quantity</label>
            </div>
            <div className="col-2 px-1 mb-2" style={{ width: "11%" }}>
              <label className="input-label">Type</label>
            </div>
            <div className="col-1 px-1 mb-2" style={{ width: "13%" }}>
              <label className="input-label">Length</label>
            </div>
            <div className="col-1 px-1 mb-2" style={{ width: "13%" }}>
              <label className="input-label">Width</label>
            </div>
            <div className="col-1 px-1 mb-2" style={{ width: "13%" }}>
              <label className="input-label">Height</label>
            </div>
            <div className="col-1 px-1 mb-2 text-center" style={{ width: "10%" }}>
              <label className="input-label">LDM</label>
            </div>
            <div className="col-1 px-1 mb-2 text-center" style={{ width: "10%" }}>
              <label className="input-label">CBM</label>
            </div>
            <div className="col-1 px-1 mb-2" style={{ width: "12%" }}>
              <label className="input-label">Weight</label>
            </div>
            <div className="col-1 px-1 mb-2" style={{ width: "8%" }}></div>
            {palletRows.map((palletRow, key) => (
              <FormPalletRow
                palletRow={palletRow}
                id={key}
                key={key}
                removeRow={removeRow}
                handleInputChange={handleInputChange}
              ></FormPalletRow>
            ))}
            <div className="col-12 mt-2">
              <span className="calculator-label pallets-modal-button" onClick={() => addRow()}>+ Add pallet</span>
            </div>
            <hr className="mt-3"></hr>
            <div className="col-12 mt-3">
              <span className="me-3">
                <b>Total pieces: {shipmentTotal.quantity}</b>
              </span>
              <span className="me-3">
                <b>LDM: {shipmentTotal.ldm}</b>
              </span>
              <span className="me-3">
                <b>CBM: {shipmentTotal.cbm}</b>
              </span>
              <span>
                <b>Weight: {shipmentTotal.weight}</b>
              </span>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-sm calculator-button"
              data-bs-dismiss="modal"
              onClick={() => setShipmentTotalValues(shipmentTotal)}
            >
              Save & Calculate
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function roundToTwo(num) {
  return + (Math.round(num + "e+2") + "e-2");
}

function roundToThree(num) {
  return + (Math.round(num + "e+3") + "e-3");
}

export default MultipleRowsModal;

import React from "react";
import { Fragment } from "react/cjs/react.production.min";

function FormPalletRow({ palletRow, removeRow, id, handleInputChange }) {
  const packageUnitsList = ["CTN", "EUR", "FIN", "HPL", "QPL", "PLL"].map(
    (packageUnit) => {
      return (
        <option value={packageUnit} key={packageUnit}>
          {packageUnit}
        </option>
      );
    }
  );

  return (
    <Fragment>
      <div className="col-2 px-1 mt-1" style={{ width: "10%" }}>
        <input
          type="text"
          name="quantity"
          value={palletRow.quantity}
          onChange={(e) => handleInputChange(e, id)}
          className="default-input fw-600 form-control"
        />
      </div>
      <div className="col-2 px-1 mt-1" style={{ width: "11%" }}>
        <select
          name="type"
          value={palletRow.type}
          onChange={(e) => handleInputChange(e, id)}
          className="default-input fw-600 form-control"
        >
          {packageUnitsList}
        </select>
      </div>
      <div className="col-1 px-1 mt-1" style={{ width: "13%" }}>
        <input
          type="text"
          name="length"
          value={palletRow.length}
          onChange={(e) => handleInputChange(e, id)}
          className="default-input fw-600 form-control"
        />
      </div>
      <div className="col-1 px-1 mt-1" style={{ width: "13%" }}>
        <input
          type="text"
          name="width"
          value={palletRow.width}
          onChange={(e) => handleInputChange(e, id)}
          className="default-input fw-600 form-control"
        />
      </div>
      <div className="col-1 px-1 mt-1" style={{ width: "13%" }}>
        <input
          type="text"
          name="height"
          value={palletRow.height}
          onChange={(e) => handleInputChange(e, id)}
          className="default-input fw-600 form-control"
        />
      </div>
      <div className="col-1 px-1 mt-2 text-center" style={{ width: "10%" }}>
        <span>{palletRow.ldm}</span>
      </div>
      <div className="col-1 px-1 mt-2 text-center" style={{ width: "10%" }}>
        <span>{palletRow.cbm === 0 ? '?' : palletRow.cbm}</span>
      </div>
      <div className="col-1 px-1 mt-1" style={{ width: "12%" }}>
        <input
          type="text"
          name="weight"
          value={palletRow.weight}
          onChange={(e) => handleInputChange(e, id)}
          className="default-input fw-600 form-control"
        />
      </div>
      <div className="col-1 px-1 mt-1" style={{ width: "8%" }}>
        { id !== 0 && 
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => removeRow(id)}
              >
                <i className="bi bi-trash"></i>
              </button>
        }
      </div>
    </Fragment>
  );
}

export default FormPalletRow;

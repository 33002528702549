export const Countries = {
    "AL": "Albania (AL)",
    "AD": "Andorra (AD)",
    "AM": "Armenia (AM)",
    "AZ": "Azerbaijan (AZ)",
    "AT": "Austria (AT)",
    "BY": "Belarus (BY)",
    "BE": "Belgium (BE)",
    "BA": "Bosnia and Herzegovina (BA)",
    "BG": "Bulgaria (BG)",
    "HR": "Croatia (HR)",
    "CY": "Cyprus (CY)",
    "CZ": "Czech Republic (CZ)",
    "DK": "Denmark (DK)",
    "EE": "Estonia (EE)",
    "FO": "Faroe Islands (FO)",
    "FI": "Finland (FI)",
    "FR": "France (FR)",
    "GE": "Georgia (GE)",
    "DE": "Germany (DE)",
    "GI": "Gibraltar (GI)",
    "GR": "Greece (GR)",
    "HU": "Hungary (HU)",
    "IS": "Iceland (IS)",
    "IE": "Ireland (IE)",
    "IM": "Isle of Man (IM)",
    "IT": "Italy (IT)",
    "KZ": "Kazakhstan (KZ)",
    "XK": "Kosovo (XK)",
    "LV": "Latvia (LV)",
    "LI": "Liechtenstein (LI)",
    "LT": "Lithuania (LT)",
    "LU": "Luxembourg (LU)",
    "MK": "Macedonia (MK)",
    "MT": "Malta (MT)",
    "MD": "Moldova (MD)",
    "MC": "Monaco (MC)",
    "MN": "Mongolia (MN)",
    "ME": "Montenegro (ME)",
    "NL": "Netherlands (NL)",
    "NO": "Norway (NO)",
    "PL": "Poland (PL)",
    "PT": "Portugal (PT)",
    "RO": "Romania (RO)",
    "RU": "Russia (RU)",
    "SM": "San Marino (SM)",
    "RS": "Serbia (RS)",
    "SK": "Slovakia (SK)",
    "SI": "Slovenia (SI)",
    "ES": "Spain (ES)",
    "SE": "Sweden (SE)",
    "CH": "Switzerland (CH)",
    "TJ": "Tajikistan (TJ)",
    "TR": "Turkey (TR)",
    "TM": "Turkmenistan (TM)",
    "UA": "Ukraine (UA)",
    "UZ": "Uzbekistan (UZ)",
    "VA": "Vatican (VA)",
    "AF": "Afghanistan (AF)",
    "DZ": "Algeria (DZ)",
    "AS": "American Samoa (AS)",
    "AO": "Angola (AO)",
    "AI": "Anguilla (AI)",
    "AQ": "Antarctica (AQ)",
    "AG": "Antigua and Barbuda (AG)",
    "AR": "Argentina (AR)",
    "AW": "Aruba (AW)",
    "AU": "Australia (AU)",
    "BS": "Bahamas (BS)",
    "BH": "Bahrain (BH)",
    "BD": "Bangladesh (BD)",
    "BB": "Barbados (BB)",
    "BZ": "Belize (BZ)",
    "BJ": "Benin (BJ)",
    "BM": "Bermuda (BM)",
    "BT": "Bhutan (BT)",
    "BO": "Bolivia (BO)",
    "BW": "Botswana (BW)",
    "BR": "Brazil (BR)",
    "IO": "British Indian Ocean Territory (IO)",
    "VG": "British Virgin Islands (VG)",
    "BN": "Brunei (BN)",
    "BF": "Burkina Faso (BF)",
    "BI": "Burundi (BI)",
    "KH": "Cambodia (KH)",
    "CM": "Cameroon (CM)",
    "CA": "Canada (CA)",
    "CV": "Cape Verde (CV)",
    "KY": "Cayman Islands (KY)",
    "CF": "Central African Republic (CF)",
    "TD": "Chad (TD)",
    "CL": "Chile (CL)",
    "CN": "China (CN)",
    "CX": "Christmas Island (CX)",
    "CC": "Cocos Islands (CC)",
    "CO": "Colombia (CO)",
    "KM": "Comoros (KM)",
    "CK": "Cook Islands (CK)",
    "CR": "Costa Rica (CR)",
    "CU": "Cuba (CU)",
    "CW": "Curacao (CW)",
    "CD": "Democratic Republic of the Congo (CD)",
    "DJ": "Djibouti (DJ)",
    "DM": "Dominica (DM)",
    "DO": "Dominican Republic (DO)",
    "TL": "East Timor (TL)",
    "EC": "Ecuador (EC)",
    "EG": "Egypt (EG)",
    "SV": "El Salvador (SV)",
    "GQ": "Equatorial Guinea (GQ)",
    "ER": "Eritrea (ER)",
    "ET": "Ethiopia (ET)",
    "FK": "Falkland Islands (FK)",
    "FJ": "Fiji (FJ)",
    "PF": "French Polynesia (PF)",
    "GA": "Gabon (GA)",
    "GM": "Gambia (GM)",
    "GH": "Ghana (GH)",
    "GL": "Greenland (GL)",
    "GD": "Grenada (GD)",
    "GU": "Guam (GU)",
    "GT": "Guatemala (GT)",
    "GG": "Guernsey (GG)",
    "GN": "Guinea (GN)",
    "GW": "Guinea-Bissau (GW)",
    "GY": "Guyana (GY)",
    "HT": "Haiti (HT)",
    "HN": "Honduras (HN)",
    "HK": "Hong Kong (HK)",
    "IN": "India (IN)",
    "ID": "Indonesia (ID)",
    "IR": "Iran (IR)",
    "IQ": "Iraq (IQ)",
    "IL": "Israel (IL)",
    "CI": "Ivory Coast (CI)",
    "JM": "Jamaica (JM)",
    "JP": "Japan (JP)",
    "JE": "Jersey (JE)",
    "JO": "Jordan (JO)",
    "KE": "Kenya (KE)",
    "KI": "Kiribati (KI)",
    "KW": "Kuwait (KW)",
    "KG": "Kyrgyzstan (KG)",
    "LA": "Laos (LA)",
    "LB": "Lebanon (LB)",
    "LS": "Lesotho (LS)",
    "LR": "Liberia (LR)",
    "LY": "Libya (LY)",
    "MO": "Macau (MO)",
    "MG": "Madagascar (MG)",
    "MW": "Malawi (MW)",
    "MY": "Malaysia (MY)",
    "MV": "Maldives (MV)",
    "ML": "Mali (ML)",
    "MH": "Marshall Islands (MH)",
    "MR": "Mauritania (MR)",
    "MU": "Mauritius (MU)",
    "YT": "Mayotte (YT)",
    "MX": "Mexico (MX)",
    "FM": "Micronesia (FM)",
    "MS": "Montserrat (MS)",
    "MA": "Morocco (MA)",
    "MZ": "Mozambique (MZ)",
    "MM": "Myanmar (MM)",
    "NA": "Namibia (NA)",
    "NR": "Nauru (NR)",
    "NP": "Nepal (NP)",
    "AN": "Netherlands Antilles (AN)",
    "NC": "New Caledonia (NC)",
    "NZ": "New Zealand (NZ)",
    "NI": "Nicaragua (NI)",
    "NE": "Niger (NE)",
    "NG": "Nigeria (NG)",
    "NU": "Niue (NU)",
    "KP": "North Korea (KP)",
    "MP": "Northern Mariana Islands (MP)",
    "OM": "Oman (OM)",
    "PK": "Pakistan (PK)",
    "PW": "Palau (PW)",
    "PS": "Palestine (PS)",
    "PA": "Panama (PA)",
    "PG": "Papua New Guinea (PG)",
    "PY": "Paraguay (PY)",
    "PE": "Peru (PE)",
    "PH": "Philippines (PH)",
    "PN": "Pitcairn (PN)",
    "PR": "Puerto Rico (PR)",
    "QA": "Qatar (QA)",
    "CG": "Republic of the Congo (CG)",
    "RE": "Reunion (RE)",
    "RW": "Rwanda (RW)",
    "BL": "Saint Barthelemy (BL)",
    "SH": "Saint Helena (SH)",
    "KN": "Saint Kitts and Nevis (KN)",
    "LC": "Saint Lucia (LC)",
    "MF": "Saint Martin (MF)",
    "PM": "Saint Pierre and Miquelon (PM)",
    "VC": "Saint Vincent and the Grenadines (VC)",
    "WS": "Samoa (WS)",
    "ST": "Sao Tome and Principe (ST)",
    "SA": "Saudi Arabia (SA)",
    "SN": "Senegal (SN)",
    "SC": "Seychelles (SC)",
    "SL": "Sierra Leone (SL)",
    "SG": "Singapore (SG)",
    "SX": "Sint Maarten (SX)",
    "SB": "Solomon Islands (SB)",
    "SO": "Somalia (SO)",
    "ZA": "South Africa (ZA)",
    "KR": "South Korea (KR)",
    "SS": "South Sudan (SS)",
    "LK": "Sri Lanka (LK)",
    "SD": "Sudan (SD)",
    "SR": "Suriname (SR)",
    "SJ": "Svalbard and Jan Mayen (SJ)",
    "SZ": "Swaziland (SZ)",
    "SY": "Syria (SY)",
    "TW": "Taiwan (TW)",
    "TZ": "Tanzania (TZ)",
    "TH": "Thailand (TH)",
    "TG": "Togo (TG)",
    "TK": "Tokelau (TK)",
    "TO": "Tonga (TO)",
    "TT": "Trinidad and Tobago (TT)",
    "TN": "Tunisia (TN)",
    "TC": "Turks and Caicos Islands (TC)",
    "TV": "Tuvalu (TV)",
    "VI": "U.S. Virgin Islands (VI)",
    "UG": "Uganda (UG)",
    "AE": "United Arab Emirates (AE)",
    "GB": "United Kingdom (GB)",
    "US": "United States (US)",
    "UY": "Uruguay (UY)",
    "VU": "Vanuatu (VU)",
    "VE": "Venezuela (VE)",
    "VN": "Vietnam (VN)",
    "WF": "Wallis and Futuna (WF)",
    "EH": "Western Sahara (EH)",
    "YE": "Yemen (YE)",
    "ZM": "Zambia (ZM)",
    "ZW": "Zimbabwe (ZW)"
}
import SignIn from './components/SignIn';
import DirectSignIn from './components/DirectSignIn';
import Auth from './components/Authentication';
import {Route, Routes, Navigate } from 'react-router-dom'
import React from 'react';
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Fragment } from 'react/cjs/react.production.min';
import Main from './components/Main';
import Privacy from './components/Privacy';
import './NavBar.css'
import './PriceCalculator.css'
import './Shared.css'
import './SignIn.css'

function App() {
  return (
    <Fragment>
        <NotificationContainer />
        <Routes>
            <Route exact path="/sign_in" element={<SignIn/>}/>
            <Route exact path="/direct_sign_in" element={<DirectSignIn></DirectSignIn>}/>
            <Route exact path="/privacy" element={<Privacy/>}/>
            <Route exact path="*" element={<SignIn/>}/>
            <Route exact path="/home" element={<PrivateRoute><Main/></PrivateRoute>}/>
        </Routes>
    </Fragment>
  )
}

function PrivateRoute({ children }) {
  let loggedIn = Auth.isLoggedIn();
  return loggedIn ? children : <Navigate to="/sign_in"/>;
}

export default App;

import React from "react";
import {Link, useNavigate} from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import Auth from './Authentication';
import SignOutIcon from "./sign-out-icon.svg";
import ReactTooltip from 'react-tooltip';

const NavBar = (props) => {
    const navigate = useNavigate();
    const logout = () => {
        Auth.logout()
        navigate("/sign_in");
    }

    const userTooltip = `${props.data.company_name}<br />${props.data.username}`;

    return (
        <Fragment>
        <nav className="my-navbar navbar-expand-lg" id={props.location}>
            <div className="center-calculator-navbar">
                <div className="d-flex align-items-center">
                <Link to={'/home'} className="navbar-brand logo">
                    <h6 className="cargo-header">Cargo</h6>
                    <h6 className="cargo-header">Price</h6>
                    <h6 className="cargo-header">List</h6>
                </Link>
                <div>
                    <div className="vertical-line"></div>
                    <h6 className="by-word">by</h6>
                    <div className="vertical-line"></div>
                </div>
                { props.data.company_logo &&
                <img src={"data:image/png;base64," + props.data.company_logo} className="carrier-logo" />
                }
                </div>
            </div>
            <div className="navbar-end">
                <div className="text-end user-info">
                    <text>{props.data.company_name}</text><br></br>
                    <text className="fw-300">{props.data.username}</text>
                </div>
                <i className="bi bi-person" id="mobile-navbar-icon" data-place="bottom" data-html={true}
                    data-tip={userTooltip}></i>
                <button className="btn" id="mobile-navbar-icon" onClick={logout}><img src={SignOutIcon}/></button>
                <button className="log-out-button" onClick={logout}>Log out</button>
            </div>
        </nav>
        <ReactTooltip effect="solid" />
        </Fragment>
    )
    }


export default NavBar